import React from "react"

function ThanksPage () {

    return (
        <section className="grid grid-cols-12 overflow-hidden">
        <div className="col-span-12 text-center bg-gradient-to-r from-white via-ms_turquoise to-ms_blue h-screen">
            <h1 className="my-20"><span className="ms_grey">Thank you for subscribing!</span></h1>
        </div>
        </section>

    )
}

export default ThanksPage